<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ user.firstname }} {{ user.lastname }}</h3>
    <div class="resume-list">
      <div class="resume-list-inner">
        <dl v-for="role of user.roles" :key="role.id">
          <dt>
            <router-link
              v-if="user.roles[0]"
              :to="$objectViewRoute(user.roles[0])"
            >
              {{ role.name }}
            </router-link>
          </dt>
          <role-permissions :role="role" />
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import RolePermissions from "@/components/RolePermissions.vue";

export default {
  name: "user-view-authorizations",

  components: { RolePermissions },

  props: ["user"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
